import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Landing from './pages/Landing';
import Feed from './pages/Feed';
import axios from 'axios';
import CreatePost from './pages/CreatePost';
import Profile from './pages/Profile';
import PostModal from './components/PostModal';
import Admin from './pages/Admin';
import StatsPage from './pages/StatsPage';
import File from './pages/Files';
import PasswordReset from './pages/PasswordReset';
import PublicPost from './pages/PublicPost';
import { api } from './api';
import Navbar from './components/Navbar';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);

  const protectedRoutes = [
    '/feed',
    '/newpost',
    '/profile/:userID',
    '/post/:postID',
    '/admin',
    '/stats',
    '/NACS',
  ];

  const isProtectedRoute = (path) => {
    return protectedRoutes.some((route) =>
      path.startsWith(route.split(':')[0])
    );
  };

  const authenticate = async () => {
    if (loading) return; // Prevent concurrent auth calls

    setLoading(true);
    try {
      const res = await api.get('/me');
      if (JSON.stringify(res.data) !== JSON.stringify(user)) {
        setUserData(res.data);
      }
    } catch (err) {
      console.error(err);
      setUserData(null);
      if (!['/', '/reset', '/share'].includes(location.pathname)) {
        navigate('/');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleAuth = async () => {
      const currentPath = location.pathname;

      // Only authenticate for protected routes
      if (isProtectedRoute(currentPath)) {
        await authenticate();
      }

      // Handle visit logging
      const lastLog = sessionStorage.getItem('lastLog');
      const timeDiff = new Date().getTime() - lastLog;

      if (!lastLog || timeDiff > 600000) {
        axios
          .post('/logVisit', {}, { withCredentials: true })
          .catch(console.error);
        sessionStorage.setItem('lastLog', Date.now());
      }
    };

    handleAuth();
  }, [location.pathname]); // Only depend on pathname changes

  return (
    <Routes>
      <Route path="/" exact element={<Landing />} />
      <Route
        path="/feed"
        element={
          <ProtectedRoute
            user={user}
            loading={loading}
            Component={Feed}
            componentProps={{ user }}
          />
        }
      />
      <Route
        path="/newpost"
        element={
          <ProtectedRoute
            user={user}
            loading={loading}
            Component={CreatePost}
            componentProps={{ user }}
          />
        }
      />
      <Route
        path="/profile/:userID"
        element={
          <ProtectedRoute
            user={user}
            loading={loading}
            Component={Profile}
            componentProps={{ user }}
          />
        }
      />
      <Route
        path="/post/:postID"
        element={
          <ProtectedRoute
            user={user}
            loading={loading}
            Component={PostModal}
            componentProps={{ user, isPage: true }}
          />
        }
      />
      <Route
        path="/admin"
        element={
          <ProtectedRoute
            user={user}
            loading={loading}
            Component={Admin}
            componentProps={{ user }}
          />
        }
      />
      <Route
        path="/stats"
        element={
          <ProtectedRoute
            user={user}
            loading={loading}
            Component={StatsPage}
            componentProps={{ user }}
          />
        }
      />
      <Route
        path="/NACS"
        exact
        element={
          <ProtectedRoute
            user={user}
            loading={loading}
            Component={File}
            componentProps={{}}
          />
        }
      />
      <Route path="/reset" exact element={<PasswordReset />} />
      <Route path="/share" exact element={<PublicPost />} />
    </Routes>
  );
};

const ProtectedRoute = ({ user, loading, Component, componentProps }) => {
  if (loading) {
    return (
      <div>
        <Navbar user={user} />
        <div className="main-loading">
          <div className="loading-spinner">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="72"
              height="72"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-loader-circle"
            >
              <path d="M21 12a9 9 0 1 1-6.219-8.56" />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  if (!user) {
    return <div>You must be logged in to view this page.</div>;
  }

  return <Component {...componentProps} />;
};

export default App;
