// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Spinner_modal__JjZWV {\n    display: block; /* Hidden by default */\n    position: fixed; /* Stay in place */\n    z-index: 100; /* Sit on top */\n    padding-top: 100px; /* Location of the box */\n    left: 0;\n    top: 0;\n    width: 100%; /* Full width */\n    height: 100%; /* Full height */\n    overflow: auto; /* Enable scroll if needed */\n    background-color: rgb(0,0,0); /* Fallback color */\n    background-color: rgba(0, 0, 0, 0.089); /* Black w/ opacity */\n    -webkit-backdrop-filter: blur(2px);\n            backdrop-filter: blur(2px);\n    overflow: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/assets/style/Spinner.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc,EAAE,sBAAsB;IACtC,eAAe,EAAE,kBAAkB;IACnC,YAAY,EAAE,eAAe;IAC7B,kBAAkB,EAAE,wBAAwB;IAC5C,OAAO;IACP,MAAM;IACN,WAAW,EAAE,eAAe;IAC5B,YAAY,EAAE,gBAAgB;IAC9B,cAAc,EAAE,4BAA4B;IAC5C,4BAA4B,EAAE,mBAAmB;IACjD,sCAAsC,EAAE,qBAAqB;IAC7D,kCAA0B;YAA1B,0BAA0B;IAC1B,gBAAgB;AACpB","sourcesContent":[".modal {\n    display: block; /* Hidden by default */\n    position: fixed; /* Stay in place */\n    z-index: 100; /* Sit on top */\n    padding-top: 100px; /* Location of the box */\n    left: 0;\n    top: 0;\n    width: 100%; /* Full width */\n    height: 100%; /* Full height */\n    overflow: auto; /* Enable scroll if needed */\n    background-color: rgb(0,0,0); /* Fallback color */\n    background-color: rgba(0, 0, 0, 0.089); /* Black w/ opacity */\n    backdrop-filter: blur(2px);\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "Spinner_modal__JjZWV"
};
export default ___CSS_LOADER_EXPORT___;
