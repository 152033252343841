// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddUser_addUserForm__Wc3lg label {\n    color: white;\n}", "",{"version":3,"sources":["webpack://./src/assets/style/AddUser.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB","sourcesContent":[".addUserForm label {\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addUserForm": "AddUser_addUserForm__Wc3lg"
};
export default ___CSS_LOADER_EXPORT___;
