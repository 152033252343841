import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Components
import Navbar from '../components/Navbar';
import AddUser from '../components/AddUser';
import ManageUser from '../components/ManageUser';

// Style & Assets
import style from '../assets/style/Admin.module.css';
import ManageRegion from '../components/ManageRegion';

const Admin = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.user || props.user.permissionLevel < 3) {
      navigate('/feed');
    }
  }, [props.user, navigate]);

  return (
    <div>
      <Navbar user={props.user} />
      <div className={style.adminDiv}>
        <h2>Admin Dashboard</h2>
        <hr />
        <ManageUser />
        <hr />
        <AddUser />
        <hr />
        <ManageRegion />
      </div>
    </div>
  );
};

export default Admin;
