// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".File_filePage__k4Zos {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.File_filePage__k4Zos img {\n    padding-top: 1rem;\n}\n.File_filePage__k4Zos a {\n    cursor: pointer;\n}\n.File_fileList__r1Oui {\n    font-size: 1.3rem;\n    margin-top: 1.2rem;\n}\n.File_fileList__r1Oui a {\n    margin-bottom: 0.5rem;\n    display: flex;\n    justify-content: center;\n}", "",{"version":3,"sources":["webpack://./src/assets/style/File.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB;AACA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,qBAAqB;IACrB,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".filePage {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.filePage img {\n    padding-top: 1rem;\n}\n.filePage a {\n    cursor: pointer;\n}\n.fileList {\n    font-size: 1.3rem;\n    margin-top: 1.2rem;\n}\n.fileList a {\n    margin-bottom: 0.5rem;\n    display: flex;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filePage": "File_filePage__k4Zos",
	"fileList": "File_fileList__r1Oui"
};
export default ___CSS_LOADER_EXPORT___;
