// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "td, th {\n    padding: 8px;\n}\n\ntr:nth-child(even){background-color: #383838;}\n\nth {\n    padding-top: 12px;\n    padding-bottom: 12px;\n    text-align: left;\n    color: white;\n    border: none;\n}\n\n.StatsTable_statsTable__fGPBI {\n    width: 100%;\n}\n\ntable {\n    width: 100%;\n}\n\n/* Options Box */\n.StatsTable_optionsBox__NccVC {\n    display: grid;\n    grid-template-columns: 2fr 1fr;\n    gap: 10px;\n}", "",{"version":3,"sources":["webpack://./src/assets/style/StatsTable.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA,mBAAmB,yBAAyB,CAAC;;AAE7C;IACI,iBAAiB;IACjB,oBAAoB;IACpB,gBAAgB;IAChB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA,gBAAgB;AAChB;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;AACb","sourcesContent":["td, th {\n    padding: 8px;\n}\n\ntr:nth-child(even){background-color: #383838;}\n\nth {\n    padding-top: 12px;\n    padding-bottom: 12px;\n    text-align: left;\n    color: white;\n    border: none;\n}\n\n.statsTable {\n    width: 100%;\n}\n\ntable {\n    width: 100%;\n}\n\n/* Options Box */\n.optionsBox {\n    display: grid;\n    grid-template-columns: 2fr 1fr;\n    gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statsTable": "StatsTable_statsTable__fGPBI",
	"optionsBox": "StatsTable_optionsBox__NccVC"
};
export default ___CSS_LOADER_EXPORT___;
