import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import axios from 'axios';
import PullToRefresh from 'react-simple-pull-to-refresh';

/* Style and Assets */
import style from '../assets/style/Feed.module.css';
import '../assets/style/spinner.css';
import Post from '../components/Post';
import BottomNavbar from '../components/BottomNavbar';
import LeftFrame from '../components/LeftFrame';

const Feed = (props) => {
  const navigate = useNavigate();

  // Setup State
  const [feed, setFeed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [batch, setBatch] = useState(0);
  const [filter, setFilter] = useState('none');
  const [regionFilter, setRegionFilter] = useState('none');
  const [timeFilter, setTimeFilter] = useState('none');
  const [searchFilter, setSearchFilter] = useState('');
  const [refreshing, setRefreshing] = useState(false);

  const bottomRef = useRef();
  const bottomInView = useOnScreen(bottomRef);

  const [noNewPosts, setNoNewPosts] = useState(0);
  const [gotPostsList, setGotPostsList] = useState([]);

  const [stickyModalOpen, setStickyModalOpen] = useState(false);

  useEffect(() => {
    if (!bottomInView) {
      console.log('Bottom not in view');
      return;
    }

    console.log('Bottom in view');

    // Get Value of ID bottomRef
    let elm = document.getElementById('bottomRef');

    // Get value from elm
    if (!elm) {
      return;
    }
    let val = elm.getAttribute('value');

    // If the value is in the gotPostsList, return
    if (gotPostsList.includes(val)) {
      console.log('Already got this post: ' + val);
      return;
    }

    getFeed(batch, filter, regionFilter, timeFilter, searchFilter);

    // Add to gotPostsList
    console.log('Adding to gotPostsList: ' + val);
    setGotPostsList(gotPostsList.concat(val));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomInView, batch]);

  useEffect(() => {
    // Get Profile Preferences
    axios({
      method: 'get',
      url: '/getProfilePrefs',
      withCredentials: true,
    })
      .then(function (res) {
        console.log(res.data);
        let regionFilter = 'none';
        if (res.data.regionFilter !== -1) {
          setRegionFilter(res.data.regionFilter);
          regionFilter = res.data.regionFilter;
        }

        // Get Feed
        getFeed(0, 'none', regionFilter, 'none', 'none');
      })
      .catch((error) => {
        console.log(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function toTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    new Promise((resolve, reject) => handleRefresh(resolve, reject));
  }

  function getFeed(
    batchNum,
    feedFilter,
    regionFilter,
    timeFilter,
    searchFilter
  ) {
    //
    axios({
      method: 'get',
      url: '/getPosts',
      withCredentials: true,
      params: {
        batch: batchNum,
        filter: feedFilter,
        regionFilter: regionFilter,
        timeFilter: timeFilter,
        searchFilter: searchFilter,
      },
    })
      .then(function (res) {
        if (res.data) {
          // Update State
          if (batchNum === 0) {
            setFeed(res.data);
            setBatch(batchNum + 1);
            setLoading(false);
            return;
          }
          let temp = feed.concat(res.data);
          setFeed(temp);

          setLoading(false);
          setBatch(batch + 1);
        } else {
          // No New Posts
          setNoNewPosts(true);
        }
      })
      .catch((error) => {
        // If 401 Ignore
        if (error.response.status === 401) {
          navigate('/');
          return;
        }
      });
  }

  //
  function removePostFromFeed(postID) {
    // Filter Feed
    let newFeed = feed.filter(function (post) {
      return post.postID !== postID;
    });

    console.log(newFeed);

    // Update State
    setFeed(newFeed);
  }

  async function handleRefresh(resolve, reject) {
    setTimeout(() => {
      // Hide Extra Filters
      let extraFilters = document.getElementById('extraFilters');
      extraFilters.style.display = 'none';

      setFilter('none');
      setRegionFilter('none');
      setTimeFilter('none');
      setSearchFilter('');
      setFeed([]);
      setBatch(0);
      setRefreshing(true);
      getFeed(0, 'none', 'none', 'none', 'none');
      resolve();
    }, 200);
  }

  async function changeFilter(newFilter) {
    // If the filter is the same as the current filter, set the filter to none
    if (newFilter === filter) {
      newFilter = 'none';
    }

    setFilter(newFilter);
    setFeed([]);
    setBatch(0);
    setRefreshing(true);
    getFeed(0, newFilter, regionFilter, timeFilter, searchFilter);
  }

  async function changeSearchFilter(newSearchFilter) {
    if (newSearchFilter === searchFilter || newSearchFilter === '') {
      newSearchFilter = 'none';
    }

    setSearchFilter(newSearchFilter);
    setFeed([]);
    setBatch(0);
    setRefreshing(true);
    getFeed(0, filter, regionFilter, timeFilter, newSearchFilter);
  }

  async function changeRegionFilter(newRegion) {
    // If the filter is the same as the current filter, set the filter to none
    if (newRegion === regionFilter) {
      newRegion = 'none';
    }

    setRegionFilter(newRegion);
    setFeed([]);
    setBatch(0);
    setRefreshing(true);
    getFeed(0, filter, newRegion, timeFilter, searchFilter);
  }

  async function changeTimeFilter(newTime) {
    // If the filter is the same as the current filter, set the filter to none
    if (newTime === timeFilter) {
      newTime = 'none';
    }

    setTimeFilter(newTime);
    setFeed([]);
    setBatch(0);
    setRefreshing(true);
    getFeed(0, filter, regionFilter, newTime, searchFilter);
  }

  // What to show while the page is loading
  if (loading) {
    return (
      <div>
        <Navbar user={props.user} />
        <div className="main-loading">
          <div className="loading-spinner">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="72"
              height="72"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-loader-circle"
            >
              <path d="M21 12a9 9 0 1 1-6.219-8.56" />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="feed">
      <Navbar user={props.user} />
      <div className={style.feedParent}>
        <LeftFrame
          filter={filter}
          setFilter={changeFilter}
          regionFilter={regionFilter}
          setRegionFilter={changeRegionFilter}
          timeFilter={timeFilter}
          setTimeFilter={changeTimeFilter}
          searchFilter={searchFilter}
          setSearchFilter={changeSearchFilter}
        />
        <PullToRefresh
          isPullable={!stickyModalOpen}
          onRefresh={() =>
            new Promise((resolve, reject) => handleRefresh(resolve, reject))
          }
          className={style.feedDiv}
        >
          <div>
            {feed.map((post, index) => (
              <>
                <Post
                  key={index}
                  post={post}
                  user={props.user}
                  removePostFromFeed={removePostFromFeed}
                  setStickyModalOpen={setStickyModalOpen}
                />
                {index === feed.length - 2 && (
                  <div ref={bottomRef} value={post.postID} id="bottomRef"></div>
                )}
              </>
            ))}

            {feed.length === 0 && !refreshing && <p>No posts found</p>}
            {Boolean(noNewPosts) && (
              <p className={style.bottomText}>You're all caught up</p>
            )}
          </div>
        </PullToRefresh>
        <div></div>
      </div>
      <br />
      <BottomNavbar
        currentPage={'home'}
        toTop={toTop}
        userID={props.user.userID}
      />
    </div>
  );
};

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    try {
      observer.observe(ref.current);
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect();
      };
    } catch (error) {
      //ignore
    }
  });

  return isIntersecting;
}

export default Feed;
