// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Admin_adminDiv__2Z60F {\n    width: 80%;\n    margin: auto;\n}\n\n@media only screen and (max-width: 800px) {\n    .Admin_adminDiv__2Z60F {\n        margin: 10px 3%;\n        width: -moz-fit-content;\n        width: fit-content;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/assets/style/Admin.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI;QACI,eAAe;QACf,uBAAkB;QAAlB,kBAAkB;IACtB;AACJ","sourcesContent":[".adminDiv {\n    width: 80%;\n    margin: auto;\n}\n\n@media only screen and (max-width: 800px) {\n    .adminDiv {\n        margin: 10px 3%;\n        width: fit-content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adminDiv": "Admin_adminDiv__2Z60F"
};
export default ___CSS_LOADER_EXPORT___;
