// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManageRegion_manageRegions__qkskC h2 {\n    margin-bottom: 0;\n}\n\n.ManageRegion_manageRegions__qkskC table button {\n    background: #ccc;\n    border-radius: 10px;\n    padding: 5px 10px;\n    margin: 0.25rem;\n}", "",{"version":3,"sources":["webpack://./src/assets/style/ManageRegion.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".manageRegions h2 {\n    margin-bottom: 0;\n}\n\n.manageRegions table button {\n    background: #ccc;\n    border-radius: 10px;\n    padding: 5px 10px;\n    margin: 0.25rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manageRegions": "ManageRegion_manageRegions__qkskC"
};
export default ___CSS_LOADER_EXPORT___;
