// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BottomNavbar_bottomNavbar__tObkU {\n    background-color: var(--DarkGray);\n    box-shadow: 0 2px 8px 1px #2e2e2e;\n    overflow: hidden;\n    position: -webkit-fixed;\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n    height: 55px;\n    text-align: center;\n    display: flex;\n    align-items: center;\n    justify-content: space-evenly;\n    padding: 7.5px;\n}\n.BottomNavbar_bottomNavbar__tObkU img {\n    height: 45px;\n}\n\n@media screen and (min-width: 768px) {\n    .BottomNavbar_bottomNavbar__tObkU {\n        display: none;\n    }\n}", "",{"version":3,"sources":["webpack://./src/assets/style/BottomNavbar.module.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,iCAAiC;IACjC,gBAAgB;IAChB,uBAAuB;IACvB,eAAe;IACf,SAAS;IACT,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,cAAc;AAClB;AACA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".bottomNavbar {\n    background-color: var(--DarkGray);\n    box-shadow: 0 2px 8px 1px #2e2e2e;\n    overflow: hidden;\n    position: -webkit-fixed;\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n    height: 55px;\n    text-align: center;\n    display: flex;\n    align-items: center;\n    justify-content: space-evenly;\n    padding: 7.5px;\n}\n.bottomNavbar img {\n    height: 45px;\n}\n\n@media screen and (min-width: 768px) {\n    .bottomNavbar {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bottomNavbar": "BottomNavbar_bottomNavbar__tObkU"
};
export default ___CSS_LOADER_EXPORT___;
