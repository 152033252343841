// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StatsPage_statsPageDiv__yps0N {\n    width: 80%;\n    margin: auto;\n}\n\n.StatsPage_viewSelect__\\+zuQ5 {\n    max-width: 300px;\n}\n\n@media only screen and (max-width: 800px) {\n    .StatsPage_statsPageDiv__yps0N {\n        margin: 10px 3%;\n        width: -moz-fit-content;\n        width: fit-content;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/assets/style/StatsPage.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,eAAe;QACf,uBAAkB;QAAlB,kBAAkB;IACtB;AACJ","sourcesContent":[".statsPageDiv {\n    width: 80%;\n    margin: auto;\n}\n\n.viewSelect {\n    max-width: 300px;\n}\n\n@media only screen and (max-width: 800px) {\n    .statsPageDiv {\n        margin: 10px 3%;\n        width: fit-content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statsPageDiv": "StatsPage_statsPageDiv__yps0N",
	"viewSelect": "StatsPage_viewSelect__+zuQ5"
};
export default ___CSS_LOADER_EXPORT___;
