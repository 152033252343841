import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Components
import Navbar from '../components/Navbar';
import EditProfile from '../components/EditProfile';

// Style & Assets
import style from '../assets/style/Profile.module.css';
import BackArrow from '../assets/backArrow.svg';
import ChangePassword from '../components/ChangePassword';
import BottomNavbar from '../components/BottomNavbar';

const Profile = (props) => {
  // Setup State
  let params = useParams();
  const [profileID] = useState(params.userID);
  const [profileData, setProfileData] = useState('');
  const [notFound, setNotFound] = useState(true);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showEditPassword, setShowEditPassword] = useState(false);

  // Verify Auth
  const navigate = useNavigate();

  useEffect(() => {
    getProfileData();
  }, []);

  function getProfileData() {
    // Make API Request
    axios({
      method: 'get',
      url: '/getProfileData',
      withCredentials: true,
      params: {
        userID: params.userID,
      },
    })
      .then(function (res) {
        if (res.status === 200) {
          setProfileData(res.data);
          setNotFound(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (notFound) {
    return (
      <div>
        <Navbar user={props.user} />
        <div className={style.profilePage}>
          <button
            onClick={(e) => {
              navigate('/feed');
            }}
          >
            <img src={BackArrow} alt={'Back'} />
          </button>
          <h2>No profile found</h2>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar user={props.user} />
      <div className={style.profilePage}>
        <button
          onClick={(e) => {
            navigate('/feed');
          }}
        >
          <img src={BackArrow} alt={'Back'} />
        </button>
        <div className={style.profileBlock}>
          <div className={style.profileBlockTop}>
            <img
              className={style.profilePhoto}
              src={
                process.env.REACT_APP_API_URL +
                '/profileImages/' +
                profileData.ProfilePhotoPath
              }
              alt={'User Profile'}
            />
            <h2>
              {profileData.firstName} {profileData.lastName}
            </h2>
          </div>
          <br />
          <h3>Email: {profileData.email}</h3>
          <h3>Region: {profileData.regionName}</h3>
          <br />
          {props.user.userID === parseInt(profileID) && (
            <>
              <button
                className={style.editButton}
                onClick={(e) => {
                  setShowEditProfile(true);
                }}
              >
                Edit Preferences
              </button>
              <br />
              <br />
              <button
                className={style.editButton}
                onClick={(e) => {
                  setShowEditPassword(true);
                }}
              >
                Change PIN
              </button>
            </>
          )}
        </div>
        {showEditProfile && (
          <EditProfile
            user={props.user}
            setShowEditProfile={setShowEditProfile}
            refresh={getProfileData}
          />
        )}
        {showEditPassword && (
          <ChangePassword setShowEditPassword={setShowEditPassword} />
        )}
      </div>
      <BottomNavbar currentPage="profile" userID={props.user.userID} />
    </div>
  );
};

export default Profile;
