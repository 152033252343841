import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8080',
  timeout: 30000,
  withCredentials: true,
});

function getSessionToken() {
  if (typeof document === 'undefined') {
    return null;
  }
  return localStorage.getItem('jwt-token') || null;
}

api.interceptors.request.use(
  async (config) => {
    const sessionToken = await getSessionToken();
    if (config.headers && sessionToken) {
      config.headers['Authorization'] = `Bearer ${sessionToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      console.error('Unauthorized, redirecting to login...');

      // Clear the token
      localStorage.removeItem('jwt-token');

      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);
