import axios from 'axios';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';

import style from '../assets/style/AddUser.module.css';

// Field Masking Setup
function PhoneInput(props) {
    return (
      <InputMask 
        mask='999-999-9999' 
        value={props.value} 
        onChange={props.onChange}
        placeholder="Phone Number" 
        required autoComplete='off'/>
    );
}


const AddUser = () => {
    const[phone, setPhone] = useState('');
    const[pin, setPin] = useState();
    const[fName, setFName] = useState('');
    const[lName, setLName] = useState('');
    const[jTitle, setJTitle] = useState('');
    const[email, setEmail] = useState('');
    const[region, setRegion] = useState(0);
    const[permLevel, setPermLevel] = useState(1)

    const[message, setMessage] = useState('')

    function createUser(e) {
        e.preventDefault();

        // Make API Request
        axios({
            method: 'post',
            url: '/auth/register',
            withCredentials: true,
            data: {
                phone: phone,
                pass: pin,
                firstName: fName,
                lastName: lName,
                jobTitle: jTitle,
                email: email,
                region: parseInt(region),
                permissionLevel: parseInt(permLevel)
            }
        }).then(function(res) {
            // Clear Inputs
            clearInputs();
            alert("Added User")
            // Refresh Page
            window.location.reload();
        }).catch(error => {
            setMessage("Error")
        });
    }

    function clearInputs() {
        setPhone('');
        setPin('');
        setFName('');
        setLName('');
        setJTitle('');
        setEmail('');
        setRegion(0);
        setPermLevel(1);
    }
    
    return (
        <div>
            <h2>Add New User</h2>
            <form className={style.addUserForm} onSubmit={createUser}>
                <label>Phone Number</label>
                <PhoneInput 
                    value={phone}
                    onChange={e => setPhone(e.target.value)} />

                <label>Temporary Pin (4)</label>
                <input type="password" maxLength={4} minLength={4} 
                value={pin} required autoComplete="off"
                placeholder='4 Digit Pin'
                onChange={e => setPin(e.target.value)} />

                <label>First Name</label>
                <input type="text" placeholder='First Name' value={fName}
                    onChange={e => setFName(e.target.value)}
                    required/>

                <label>Last Name</label>
                <input type="text" placeholder='Last Name' value={lName}
                    onChange={e => setLName(e.target.value)}
                    required/>

                <label>Job Title</label>
                <input type="text" placeholder='Job Title' value={jTitle}
                    onChange={e => setJTitle(e.target.value)}
                    required/>


                <label>Email</label>
                <input type="email" placeholder='Email' value={email}
                    onChange={e => setEmail(e.target.value)}
                    required/>

                <label>Region</label>
                <select onChange={e => setRegion(e.target.value)} required>
                    <option value={0}>Gulf South</option>
                    <option value={1}>Heartland</option>
                    <option value={2}>Mid Atlantic</option>
                    <option value={3}>New England</option>
                    <option value={4}>Ozark</option>
                    <option value={5}>Pacific</option>
                    <option value={6}>South Atlantic</option>
                    <option value={7}>Xcaliber Employee</option>
                    <option value={8}>Xcaliber Owner</option>
                </select>

                <label>Permission Level</label>
                <select onChange={e => setPermLevel(e.target.value)} required defaultValue={1}>
                    <option value={0}>Guest</option>
                    <option value={1}>Normal User</option>
                    <option value={2}>Moderator</option>
                    <option value={3}>Admin</option>
                </select>

                <input type="submit" value="Create User" />
            </form>
            {message &&
                <p>{message}</p>
            }
            <br />
            <br />
        </div>
    );
};

export default AddUser;