import React, {useState, useEffect} from 'react';
import Navbar from '../components/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import EXIF from 'exif-js'; // Import the EXIF library

// Style & Assets
import style from '../assets/style/CreatePost.module.css';
import BackArrow from '../assets/backArrow.svg';
import Spinner from '../components/Spinner';
import UpArrow from '../assets/upArrow.svg';
import DownArrow from '../assets/downArrow.svg';
import BottomNavbar from '../components/BottomNavbar';
import {stateList} from "../stateList";

const CreatePost = (props) => {
    // Verify Auth
    const navigate = useNavigate();
    const[maxCommentLength, setMaxCommentLength] = useState(256);

    useEffect(() => {
        // Get Max Comment Length
        axios({
            method: 'get',
            url: '/getMaxCommentLength',
            withCredentials: true,
            }).then(function(res) {
                if (res.data === 0) {
                    setMaxCommentLength(256)
                    console.log("Max comment length not set")
                    return
                }

                console.log("Max comment length: " + res.data)
                setMaxCommentLength(res.data)
            })
            .catch(error => {
                console.log(error)
            });

        // Make API Request
        axios({
            method: 'get',
            url: '/auth/authenticate',
            withCredentials: true
        })
        .then(function(res) {
            if (res.status === 200) {
                // Set State
                props.authenticate(res.data)
            }
        })
        .catch(error => {
            // If 401 Ignore
            if (error.response.status === 401) {
                navigate("/")
                return
            }
            console.log(error)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // State
    const[caption, setCaption] = useState('');
    const[city, setCity] = useState('');
    const[state, setState] = useState('');
    const [locationData, setLocationData] = useState(null); // State for storing location data
    const[files, setFiles] = useState([]);
    const[showPostingSpinner, setShowPostingSpinner] = useState(false);
    const[postError, setPostError] = useState(false);

    // File Updater
    const fileUpdater = (e) => {
        // If type is a video, allow only 1
        if (e.target.files[0].type.includes("video")) {
            if (e.target.files.length > 1) {
                alert("You can only upload 1 file per post when uploading a video")
                setFiles(null)
                return
            }
            setFiles(e.target.files)
            return
        }

        if (e.target.files[0].type.includes("image")) {
            console.log("Image")
            const file = e.target.files[0];
            const fileURL = URL.createObjectURL(file);
            console.log(fileURL);

            // Create an image element
            const img = new Image();
            img.onload = () => {
                EXIF.getData(img, function() {
                    console.log("Getting EXIF data");
                    console.log(EXIF.getAllTags(this));
                    const lat = EXIF.getTag(this, "GPSLatitude");
                    const lon = EXIF.getTag(this, "GPSLongitude");
                    // Convert coordinates from DMS to decimal if they exist
                    const latDirection = EXIF.getTag(this, "GPSLatitudeRef");
                    const lonDirection = EXIF.getTag(this, "GPSLongitudeRef");
                    const latitude = lat ? convertDMSToDecimal(lat, latDirection) : null;
                    const longitude = lon ? convertDMSToDecimal(lon, lonDirection) : null;

                    setLocationData({ latitude, longitude });

                    // Make API Request
                    // https://us1.locationiq.com/v1/reverse?key=pk.9b31a4ee62675d1d7b09198bd628d08a&lat=${latitude}&lon={longitude}&format=json&

                    // Make API Request
                    fetch(`https://us1.locationiq.com/v1/reverse?key=pk.9b31a4ee62675d1d7b09198bd628d08a&lat=${latitude}&lon=${longitude}&format=json&`).then(async (res) => {
                        // Get JSON Data
                        const data = await res.json();
                        // Get Address
                        const address = data.address;
                        // Set City & State
                        setCity(address.city);
                        // State is name, convert to abbreviation
                        const state = address.state;
                        for (let i = 0; i < stateList.length; i++) {
                            if (stateList[i].name === state) {
                                setState(stateList[i].abbreviation);
                                break;
                            }
                        }
                    }).catch((err) => {
                        console.log(err);
                    });

                    // Release the object URL
                    URL.revokeObjectURL(fileURL);
                });
            };
            img.src = fileURL;
        } else {
            console.log("Not an image file");
        }
        
        if (e.target.files.length > 5) {
            alert("You can only upload a max of 5 images per post")
            setFiles(null)
            return
        }
        setFiles(e.target.files)
    }

    // Helper function to convert DMS (Degrees, Minutes, Seconds) format to decimal
    const convertDMSToDecimal = (dms, direction) => {
        let decimal = dms[0] + dms[1] / 60 + dms[2] / 3600;

        if (direction === 'S' || direction === 'W') {
            decimal = decimal * -1;
        }

        return decimal;
    };

    //
    function createPost(e) {
        e.preventDefault();

        if (!files) {
            return
        }

        // Set Spinner
        setShowPostingSpinner(true);

        // Setup Data
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append(files[i].name, files[i])
        }

        // Handle Location Data to Caption
        if (city !== "" && state !== "" && false) {
            let stateFullName = ""
            for (let i = 0; i < stateList.length; i++) {
                if (stateList[i].abbreviation === state) {
                    stateFullName = stateList[i].name
                    break;
                }
            }
            let newCaption = city + ", " + stateFullName + " :: " + caption
            setCaption(newCaption)
            formData.append("caption", newCaption)
        }
        formData.append("caption", caption)

        formData.append("city", city)
        if (state === undefined) {
            setState("")
        }
        formData.append("state", state)

        // API Request
        axios({
            method: 'post',
            url: '/createPost',
            maxContentLength: -1,
            maxBodyLength: -1,
            withCredentials: true,
            headers: { "Content-Type": "multipart/form-data" },
            data: formData,
          })
          .then(function(res) {
            // If Login Successful Redirect
            if(res.status === 200) {    
                navigate('/feed')
            } else {
                console.log(res.status)
                setShowPostingSpinner(false);
                setPostError(true);
            }
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
            if (error.response.status === 400) {
                alert(error.response.data)
                setShowPostingSpinner(false);
                return
            }
            setShowPostingSpinner(false);
            setPostError(true);
          });
    }

    // Reordering
    function moveImageUp(e, index) {
        e.preventDefault();

        let temp = [];
        let newIndex = index - 1;

        if (newIndex < 0) {
            return
        }

        for (let i = 0; i < files.length; i++) {
            // Check if new
            if (i === newIndex) {
                temp.push(files[index]);
            }
            // Skip reordered item
            if (i === index) {
                continue
            }

            temp.push(files[i])
        }

        setFiles(temp)
    }

    function moveImageDown(e, index) {
        e.preventDefault();
        let temp = [];
        let newIndex = index + 1;

        if (newIndex > files.length-1) {
            return
        }

        for (let i = 0; i < files.length; i++) {
            if (i === index) {
                temp.push(files[i+1])
                temp.push(files[index])
                i++
                continue  
            }
            
            temp.push(files[i])
        }

        setFiles(temp)
    }
    
    // Function to Resize Text Area
    function resize () {
        var text = document.getElementById("createPostText");
        let temp = text.scrollHeight+'px';

        // Minium of 5 rows, add additional rows as needed
        if (text.scrollHeight < 100) {
            temp = '100px';
        }

        text.style.height = temp;
    }

    return (
        <div>
            <Navbar user={props.user} authenticate={props.authenticate}/>
            <div className={style.createPostDiv}>
                <div className={style.topBar}>
                    <button><Link to="/feed"><img src={BackArrow} alt={"Back Arrow"}/></Link></button>
                    <h2>New Post</h2>
                    <p></p>
                </div>

                <form onSubmit={createPost}>
                    <label>Caption</label>
                    <textarea name="Text1" id="createPostText" rows={5} value={caption} onChange={e => {setCaption(e.target.value); resize()}}
                        maxLength={maxCommentLength} placeholder={"Enter caption..."} required />

                    {/*<label>Location</label>*/}
                    {/*<div className={style.locationDiv}>*/}
                    {/*    <input type="text" name="city" placeholder="City" value={city} onChange={e => {setCity(e.target.value)}} required/>*/}
                    {/*    <select name="state" value={state} onChange={e => { setState(e.target.value) }} required>*/}
                    {/*        {stateList.map((state, index) => (*/}
                    {/*            <option key={index} value={state.abbreviation}>{state.name}</option>*/}
                    {/*        ))}*/}
                    {/*    </select>*/}
                    {/*</div>*/}

                    <label>Media</label>
                    <input type="file" onChange={e => fileUpdater(e)}
                        accept="video/*,image/*" name="photos" multiple="multiple" required/>            

                    <div className={style.previewDiv}>
                    {files && [...files].map((file, index)=>(
                        <div key={file.name.toString()} className={style.previewContainer}>
                            {file.type.includes("video") 
                                ? <video src={URL.createObjectURL(file)} alt='uploaded' className={style.previewImages} controls/>
                                : <img src={URL.createObjectURL(file)} alt='uploaded' className={style.previewImages} />
                            }
                            <div className={style.buttonBox}>
                                {index !== 0 &&
                                    <button onClick={e => {moveImageUp(e, index)}}><img src={UpArrow} alt='up'/></button>
                                }
                                {index !== files.length-1  &&
                                    <button onClick={e => {moveImageDown(e, index)}}><img src={DownArrow} alt='down'/></button>
                                }
                            </div>
                        </div>
                    ))}
                    </div>

                    <input type="submit" value="Share Post" className={style.postButton} />
                    {postError &&
                        <p>There was an error uploading your post, please try again later</p>
                    }
                </form>
                {showPostingSpinner &&
                    <Spinner />
                }
            </div>
            <BottomNavbar userID={props.user.userID}/>
        </div>
    );
};

export default CreatePost;