// Dependencies
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import InputMask from 'react-input-mask';

// Style & Assets
import style from "../assets/style/Landing.module.css";
import Logo from "../assets/logo.png";

const Landing = (props) => {
    // If user Logged in Redirect
    const navigate = useNavigate();

    const[isResetPIN, setIsResetPIN] = useState(false);
    const[resetSent, setResetSent] = useState(false);

    const[email, setEmail] = useState('');

    useEffect(() => {
        if (props.user) {
            navigate("/feed");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    
    const[phone, setPhone] = useState('');
    const[password, setPassword] = useState('');
    
    // Error States
    const[isError, setIsError] = useState(false); 
    const[errorMessage, setErrorMessage] = useState('')
    
    function login(e) {
        e.preventDefault();

        // Make API Request
        axios({
            method: 'post',
            url: '/auth/login',
            withCredentials: true,
            data: {
              phone: phone,
              pass: password
            }
          })
          .then(function(res) {
            const token = res.data.token;
            localStorage.setItem('jwt-token', token);
            navigate('/feed');
          })
          .catch(error => {
            if (error.response.status === 401) {
                setIsError(true)
                setErrorMessage("Incorrect Phone Number or PIN")
            } else if (error.response.status === 403) {
                setIsError(true)
                setErrorMessage("Your account has been locked, please contact an admin for additional information")
            } else {
                setIsError(true)
                setErrorMessage("There has been an error")
            }
          });
    }


    // Send Reset PIN Request
    function resetPIN(e) {
        e.preventDefault();

        // Make API Request
        axios({
            method: 'post',
            url: '/sendResetEmail',
            withCredentials: true,
            data: {
                email: email
            }
        }).then(function(res) {
            setResetSent(true)
        }).catch(error => {
            alert("There has been an error")
        });
    }
            
    return (
        <div className={style.landingDiv}>
            <div className={style.nav}>
                <img src={Logo} alt={"Xcaliber International Logo"} className={style.logo} />
            </div>
            
            <div className={style.contentBox}>
                {!isResetPIN &&
                    <form onSubmit={login}>
                            <div className={style.topBar}>
                                <h3>Login</h3>
                            </div>

                            <label>Phone Number</label>
                            <InputMask
                                mask='999-999-9999'
                                value={phone} 
                                onChange={e => setPhone(e.target.value)}
                                placeholder="Phone Number" 
                                required autoComplete='username'/>

                            <label>Pin</label>
                            <input type="password" placeholder='PIN' maxLength={4} 
                                required autoComplete='current-password' ip="pin"
                                onChange={e => setPassword(e.target.value)} />
                            
                            {isError &&
                                <p className={style.error}>{errorMessage}</p>
                            }

                            <input type="submit" value="Login" autoFocus/>

                            <p className={style.forgot} onClick={() => setIsResetPIN(true)}>Forgot PIN?</p>
                    </form>
                }

                {isResetPIN &&
                    <form onSubmit={resetPIN}>
                        <div className={style.topBar}>
                            <h3>Reset PIN</h3>
                        </div>
                        
                        {!resetSent &&
                            <>
                                <label>Email</label>
                                <input type="email" placeholder='Email' required onChange={e => {setEmail(e.target.value)}} />

                                <input type="submit" value="Reset PIN" autoFocus/>

                            </>
                        }

                        {resetSent &&
                            <>
                                <p className={style.success}>Check your email for a link to reset your pin</p>
                                <p onClick={() => setResetSent(false)}>Send New Link</p>
                            </>
                        }

                        <p className={style.forgot} onClick={() => setIsResetPIN(false)}>Back to Login</p>
                    </form>
                }
            </div>
        </div>
    );
};

export default Landing;